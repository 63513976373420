import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'axios';
import _ from 'lodash';
import type { Assessment, Category } from 'src/types/assessment';

interface AssessmentsState {
  assessments: Assessment[];
  categories: Category[];
  selectedAssessment: Assessment;
  isModalOpen: boolean;
  isNew: boolean;
  isAssignModalOpen: boolean;
}

const initialState: AssessmentsState = {
  assessments: [],
  categories: [],
  selectedAssessment: undefined,
  isModalOpen: false,
  isNew: false,
  isAssignModalOpen: false,
};

const slice = createSlice({
  name: 'assessments',
  initialState,
  reducers: {
    createAssessment(state: AssessmentsState, action: PayloadAction<{}>) {
      state.isModalOpen = false;
    },
    openModal(state: AssessmentsState) {
      state.isModalOpen = true;
    },
    closeModal(state: AssessmentsState) {
      state.isModalOpen = false;
    },
    getCategories(state: AssessmentsState, action: PayloadAction<{ categories: Category[] }>) {
      if (action.payload) {
        state.categories = action.payload.categories;
      } else {
        state.categories = [];
      }
    },
    openAdd(state: AssessmentsState) {
      state.isNew = true;
    },
    closeAdd(state: AssessmentsState) {
      state.isNew = false;
    },
    openAssignModal(state: AssessmentsState) {
      state.isAssignModalOpen = true;
    },
    closeAssignModal(state: AssessmentsState) {
      state.isAssignModalOpen = false;
    },
  },
});

export const reducer = slice.reducer;

export const openModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const openAdd = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openAdd());
};

export const closeAdd = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeAdd());
};

export const openAssignModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openAssignModal());
};

export const closeAssignModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeAssignModal());
};

export const createAssessment = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.createAssessment({}));
};

export const getCategories = (): AppThunk => async (dispatch) => {
  const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];
  const response = await axios({
    method: 'get',
    url: `v2/therapist/${therapistId}/protocols/categories`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
  });
  dispatch(slice.actions.getCategories({ categories: response.data }));
};

export default slice;
