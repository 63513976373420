import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

type GuestGuardProps = {
  children?: ReactNode;
};

const GuestGuard = ({ children }: GuestGuardProps) => {
  const { user } = useAuth();
  const role = user.userType || null;

  if (!['T', 'CH'].includes(role)) {
    return <Redirect to="/404" />;
  }

  return <>{children}</>;
};

export default GuestGuard;
