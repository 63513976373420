import axios from 'axios';
import { QuestionnaireQueryParams } from 'src/types/questionnaire';

const therapistId = () =>
  localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user'))['userId'] : null;

export const getAllCategories = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `/v2/therapist/${therapistId()}/questionnaires/categories`,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    });
    return response.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const createCategory = async (categoryName: string) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/v2/therapist/${therapistId()}/questionnaires/category`,
      headers: { Authoriazation: 'Bearer ' + localStorage.getItem('accessToken') },
      data: {
        name: categoryName,
      },
    });
    return {
      data: response.data,
      error: false,
      message: 'Questionnaire Category was successfully created.',
    };
  } catch (e) {
    console.error(e);
    return {
      error: true,
      message:
        'Failed to create new Questionnaire Category, please try again later or contact support.',
    };
  }
};

export const getAllQuestionnaires = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `/v2/therapist/${therapistId()}/questionnaires`,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    });
    return response.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getQuestionnaireResponses = async (clientId: string) => {
  try {
    const response = await axios({
      method: 'get',
      url: `/v2/therapist/${therapistId()}/client/${clientId}/questionnaireResponses`,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    });
    return response.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getPaginatedQuestionnaires = async (data: QuestionnaireQueryParams): Promise<any> => {
  try {
    const response = await axios({
      method: 'get',
      url: `v2/therapist/${therapistId()}/questionnaires`,
      params: {
        ...data,
      },
    });
    return {
      error: false,
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return {
      error: true,
      data: { limit: 10, questionnaires: [], startFrom: 0, totalSessions: 0 },
      message: 'Failed to fetch sessions.',
    };
  }
};

export const getSpecificQuestionnaire = async (questionnaire_id: string) => {
  try {
    const response = await axios({
      method: 'get',
      url: `/v2/therapist/${therapistId()}/questionnaire/${questionnaire_id}`,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    });
    return response.data;
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const createQuestionnaire = async (questionnaire: any) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/v2/therapist/${therapistId()}/questionnaire`,
      headers: { Authorization: 'Bearer' + ' ' + localStorage.getItem('accessToken') },
      data: questionnaire,
    });

    return {
      data: response.data,
      error: false,
      message: 'Questionnaire was successfully created.',
    };
  } catch (e) {
    console.error(e);
    return {
      error: true,
      message: 'Failed to create new Questionnaire, please try again later or contact support.',
    };
  }
};

export const updateQuestionnaire = async (updatedQuestionnaire: any, questionnaire_id: string) => {
  try {
    const response = await axios({
      method: 'put',
      url: `/v2/therapist/${therapistId()}/questionnaire/${questionnaire_id}`,
      headers: { Authorization: 'Bearer' + ' ' + localStorage.getItem('accessToken') },
      data: updatedQuestionnaire,
    });
    return {
      data: response.data,
      error: false,
      message: 'Questionnaire was successfully updated.',
    };
  } catch (e) {
    console.error(e);
    return {
      error: true,
      message: 'Failed to update Questionnaire, please try again later or contact support.',
    };
  }
};

export const deleteQuestionnaire = async (questionnaire_id: string) => {
  try {
    await axios({
      method: 'DELETE',
      url: `/v2/therapist/${therapistId()}/questionnaire/${questionnaire_id}`,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    });
    return {
      error: false,
      message: 'Questionnaire was successfully deleted.',
    };
  } catch (e) {
    console.error(e);
    return {
      error: true,
      message: 'Failed to delete Questionnaire, please try again later or contact support.',
    };
  }
};
